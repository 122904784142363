var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tab-employee" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "vx-card p-6" },
        [
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Questões",
                      "data-vv-as": "Nº de Questões",
                      name: "qtd_1",
                    },
                    model: {
                      value: _vm.test.qtd_1,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_1", $$v)
                      },
                      expression: "test.qtd_1",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_1"),
                          expression: "errors.has('qtd_1')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_1")))]
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Questões",
                      "data-vv-as": "Nº de Questões",
                      name: "qtd_2",
                    },
                    model: {
                      value: _vm.test.qtd_2,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_2", $$v)
                      },
                      expression: "test.qtd_2",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_2"),
                          expression: "errors.has('qtd_2')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_2")))]
                  ),
                ],
                1
              ),
              _vm._m(1),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Questões",
                      "data-vv-as": "Nº de Questões",
                      name: "qtd_3",
                    },
                    model: {
                      value: _vm.test.qtd_3,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_3", $$v)
                      },
                      expression: "test.qtd_3",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_3"),
                          expression: "errors.has('qtd_3')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_3")))]
                  ),
                ],
                1
              ),
              _vm._m(2),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Questões",
                      "data-vv-as": "Nº de Questões",
                      name: "qtd_4",
                    },
                    model: {
                      value: _vm.test.qtd_4,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_4", $$v)
                      },
                      expression: "test.qtd_4",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_4"),
                          expression: "errors.has('qtd_4')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_4")))]
                  ),
                ],
                1
              ),
              _vm._m(3),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Questões",
                      "data-vv-as": "Nº de Questões",
                      name: "qtd_5",
                    },
                    model: {
                      value: _vm.test.qtd_5,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_5", $$v)
                      },
                      expression: "test.qtd_5",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_5"),
                          expression: "errors.has('qtd_5')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_5")))]
                  ),
                ],
                1
              ),
              _vm._m(4),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "vx-row w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-0",
                    attrs: {
                      type: "number",
                      label: "Nº de Provas",
                      "data-vv-as": "Nº de Provas",
                      name: "qtd_tests",
                    },
                    model: {
                      value: _vm.test.qtd_tests,
                      callback: function ($$v) {
                        _vm.$set(_vm.test, "qtd_tests", $$v)
                      },
                      expression: "test.qtd_tests",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("qtd_tests"),
                          expression: "errors.has('qtd_tests')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("qtd_tests")))]
                  ),
                ],
                1
              ),
              _vm._m(5),
            ]),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.print },
                    },
                    [_vm._v("Gerar Simulado")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("LEGISLAÇÃO SINALIZAÇÃO")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("PRIMEIROS SOCORROS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("DIREÇÃO DEFENSIVA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("MECÂNICA E CONVÍVIO")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("LEGISLAÇÃO")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-8" }, [
      _c("h6", [_vm._v("QUANTIDADE DE PROVAS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }