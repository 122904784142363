<template>
  <div id="tab-employee">
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <div class="vx-card p-6">

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Questões" v-model="test.qtd_1" data-vv-as="Nº de Questões" v-validate.initial="'required'" name="qtd_1" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_1')">{{ errors.first('qtd_1') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>LEGISLAÇÃO SINALIZAÇÃO</h6>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Questões" v-model="test.qtd_2" data-vv-as="Nº de Questões" v-validate.initial="'required'" name="qtd_2" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_2')">{{ errors.first('qtd_2') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>PRIMEIROS SOCORROS</h6>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Questões" v-model="test.qtd_3" data-vv-as="Nº de Questões" v-validate.initial="'required'" name="qtd_3" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_3')">{{ errors.first('qtd_3') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>DIREÇÃO DEFENSIVA</h6>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Questões" v-model="test.qtd_4" data-vv-as="Nº de Questões" v-validate.initial="'required'" name="qtd_4" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_4')">{{ errors.first('qtd_4') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>MECÂNICA E CONVÍVIO</h6>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Questões" v-model="test.qtd_5" data-vv-as="Nº de Questões" v-validate.initial="'required'" name="qtd_5" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_5')">{{ errors.first('qtd_5') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>LEGISLAÇÃO</h6>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="vx-row w-full">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Nº de Provas" v-model="test.qtd_tests" data-vv-as="Nº de Provas" v-validate.initial="'required'" name="qtd_tests" />
            <span class="text-danger text-sm" v-show="errors.has('qtd_tests')">{{ errors.first('qtd_tests') }}</span>
          </div>
          <div class="vx-col md:w-4/5 w-full mt-8">
            <h6>QUANTIDADE DE PROVAS</h6>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="print" :disabled="!validateForm">Gerar Simulado</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      popupShowPdf: false,
      showPdfTitle: 'Simulado',
      srcPdf: '',
      test: {
        qtd_1: 0,
        qtd_2: 0,
        qtd_3: 0,
        qtd_4: 0,
        qtd_5: 5,
        qtd_tests: 1
      }
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any()
    }
    // user () {
    //   return this.$store.state.AppActiveUser
    // }
  },
  methods: {
    async print () {
      try {
        this.$vs.loading()
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/test/display/`, this.test)
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        console.log(error)
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  }
}
</script>

<style>
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
</style>
